<template>
  <div class="flex-center footer-container">
    @备案号 2102120909203120 - 成都云驰科技有限公司
  </div>
</template>

<script>
</script>

<style lang="scss">
.el-footer {
  background-color: var(--theme-bg-color);
  color: var(--el-text-color-primary);
  text-align: center;
  height: auto;
}
.footer-container {
  color: var(--text-gray-color);
  font-size: 14px;
  padding-bottom: 10px;
}
</style>
