<template>
  <div class="home">
    <el-container>
      <Left></Left>
      <el-container>
        <el-header>
          <Top></Top>
        </el-header>

        <el-main style="height: 0; flex-grow: 1">
          <div class="main">
            <router-view></router-view>
          </div>
        </el-main>
        <el-footer>
          <Foot></Foot>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Left from "@/components/layout/left.vue";
import Top from "@/components/layout/top.vue";
import Foot from "@/components/layout/foot.vue";
import API from "@/plugins/api.js";
import { useStore } from "vuex";

export default {
  name: "Home",
  components: {
    Left,
    Top,
    Foot,
  },
  setup() {
    const store = useStore();
    API.getUserInfo().then((res) => {
      store.commit("setUserInfo", res);
    });
    return {
      store,
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: calc(100vh);
  .el-header {
    height: auto;
    line-height: 80px;
    padding: 0px;
    .el-menu--horizontal > .el-menu-item {
      height: 80px;
    }
    .el-menu--horizontal {
      border: none;
    }
  }

  .el-main {
    background-color: var(--theme-bg-color);
    color: var(--el-text-color-primary);
    text-align: center;
    min-height: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .main {
      // margin: 20px;
      // height: calc(100vh - 240px);
      flex: 1;
      border-radius: 10px;
      background-color: #ffffff;
      overflow: hidden;
    }
  }
}

body,
.el-container {
  margin: 0px !important;
  height: 100%;
}
</style>
