export const topMenuList = [
  // {
  //   title: "系统首页",
  //   token: "Moudle_nGIlaFTdmn183fxbGSU4kEwxLjeq",
  // },
  {
    title: "非机动车",
    token: "Moudle_GKACMuSfVYdVQzyr6dMns7u8LJXc",
  },
];
export const leftMenuList = [
  {
    title: "运营管理",
    path: "/operations/temporary",
    icon: "icon-a-zu4744",
    token: "Menu_TaO8Ce7LstDzqDAEWqIwv1XHtCD8",
  },
  {
    title: "车场管理",
    path: "/parking",
    icon: "icon-a-zu4745",
    token: "Menu_tAlrDIED4tcJaiBnad6vCVtsL0dH",
  },
  {
    title: "财务数据",
    path: "/financing",
    icon: "icon-a-zu4746",
    token: "Menu_fzzHaIUkFdWQjk9wq051nQEzehin",
  },
];
export const leftDefalutMenuList = [
  {
    title: "组织人员",
    path: "/organizer",
    icon: "icon-a-zu4740",
    token: "Menu_t6UH9pKah8HUukRqIRE3eVBNfu9l",
  },
];
export const rightMenuList = [
  {
    title: "临停收费记录",
    path: "/operations/temporary",
    token: "List_YpPh5NDT9ApuswiNoahAjKT4xidH",
  },
  {
    title: "车辆列表",
    path: "/operations/car",
    token: "List_b2pK6ZKqhKoQdfsJmqnphB2TfEPy",
  },
  {
    title: "车场管理",
    path: "/parking",
    token: "List_JNXZLi5hwbyYgOylZL4zJqMHU3nG",
  },
  {
    title: "闸机管理",
    path: "/parking/gate-machine",
    token: "List_MV4gu5cD6FX98yInqd82HDjCZcxj",
  },

  {
    title: "IC卡管理",
    path: "/parking/card",
    token: "List_FOTPLhNcmKH2JrZCnIp6e3N0AOH9",
  },
  {
    title: "收费模板",
    path: "/parking/charge",
    token: "List_upQ3qOUCRcUTKbImdc8XhkOK99LZ",
  },
  {
    title: "订单管理",
    path: "/financing",
    token: "List_FWEZAxLCghEPOkpxZQFT5n21HKLD",
  },
  {
    title: "报表",
    path: "/financing/statement",
    token: "List_4Nr8PSoeuxYdLXlibYQ4W9cQHlii",
  },
  {
    title: "组织与成员",
    path: "/organizer",
    token: "List_maa7rpqJCqYIS88ZGkd3Bl6uKvGG",
  },
  {
    title: "项目管理",
    path: "/organizer/project",
    token: "List_CExnyVYyuWWPHDztmy6EF8jYCPJj",
  },
  {
    title: "角色管理",
    path: "/organizer/role",
    token: "List_xHz8e2q6hKSZHEwFsviQhqEnYF8J",
  },
];
