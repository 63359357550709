<template>
  <div v-if="dialogVisible" class="confirm-dialog">
    <el-dialog v-model="dialogVisible" width="400px" center>
      <div class="tips">
        <img src="@/assets/img/warning.png" />
        <div class="title">{{ title }}</div>
      </div>
      <template #footer>
        <el-button
          @click="dialogVisible = false"
          type="primary"
          class="cancle"
          plain
          round
          >取消</el-button
        >
        <el-button class="confirm" @click="wConfirm" round>确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["wConfirm"],
  setup(props, { emit }) {
    const dialogVisible = ref(false);

    const wConfirm = () => {
      // dialogVisible.value = false;
      emit("wConfirm", "1333");
    };
    const show = () => {
      dialogVisible.value = true;
    };
    const close = () => {
      dialogVisible.value = false;
    };
    return {
      show,
      close,
      wConfirm,
      dialogVisible,
    };
  },
};
</script>

<style lang="scss">
.confirm-dialog {
  .el-dialog__header {
    background-color: #fff;
  }
  .tips {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 100px;
    }
    .title {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .el-button {
    padding: 12px 60px;
    background-color: var(--theme-color);
  }

  .cancle {
    border: 2px solid var(--theme-color);
    padding: 10px 60px;
    color: var(--theme-color);
    background-color: #ffffff;
    &:hover {
      background-color: var(--theme-color);
      color: #fff;
    }
  }
  .confirm {
    color: #ffffff;
  }
  .el-dialog__footer {
    display: flex;
    padding: 20px 30px;
    justify-content: space-between;
  }
}
</style>
