<template>
  <div v-if="dialogVisible">
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      :width="width"
      center
      :before-close="close"
    >
      <slot></slot>
      <template #footer v-if="showAction">
        <el-button @click="close" type="primary" class="cancle" plain round
          >返回</el-button
        >
        <el-button class="confirm" @click="wConfirm" round :loading="isLoading"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    showAction: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "50%",
    },
  },
  emits: ["wConfirm", "wCancel"],
  setup(props, { emit }) {
    const dialogVisible = ref(false);
    const isLoading = ref(false);
    const show = () => {
      dialogVisible.value = true;
    };
    const showLoading = () => {
      isLoading.value = true;
    };
    const closeLoading = () => {
      isLoading.value = false;
    };
    const wConfirm = () => {
      // dialogVisible.value = false;
      emit("wConfirm", "1333");
    };
    const close = () => {
      closeLoading();
      dialogVisible.value = false;
      emit("wCancel", "1333");
    };
    return {
      show,
      wConfirm,
      dialogVisible,
      close,
      isLoading,
      showLoading,
      closeLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  padding: 12px 60px;
  background-color: var(--theme-color);
}

.cancle {
  border: 2px solid var(--theme-color);
  padding: 10px 60px;
  color: var(--theme-color);
  background-color: #ffffff;
}

.confirm {
  color: #ffffff;
}
.el-dialog__footer {
  .el-button {
    &:nth-child(2) {
      margin-left: 80px;
    }
  }
}
</style>
