<template>
  <div class="left-menu-container">
    <el-aside :width="!isToggleAside ? '250px' : '150px'">
      <div>
        <div class="logo-container flex-center">
          <img
            :class="!isToggleAside ? 'logo' : 'small-logo'"
            src="https://z.ygddzy.cn/yc/source/logo-title.png"
          />
        </div>
        <div class="select-container">
          <el-dropdown @command="handleCommand">
            <el-button type="primary">
              <span>{{ userInfo.realname }}</span>
              <span class="triangle"></span>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu
                :style="{ width: !isToggleAside ? '200px' : '120px' }"
              >
                <el-dropdown-item command="1">修改密码 </el-dropdown-item>
                <el-dropdown-item command="2">安全退出 </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <el-menu
          :default-active="leftActiveToken"
          class="left-nav"
          ref="menu1"
          v-if="leftMenu.length"
        >
          <el-menu-item
            v-for="(item, index) in leftMenu"
            :disabled="item.title ? false : true"
            :class="
              activeLeftIndex < leftMenu.length && activeLeftIndex == index + 1
                ? 'active-bg '
                : activeLeftIndex == index - 1
                ? 'active-bg '
                : ''
            "
            :index="item.token"
            :key="index"
            @click="handleChange(item, index, 1)"
          >
            <div
              :class="
                activeLeftIndex < leftMenu.length &&
                activeLeftIndex == index + 1
                  ? 'active-before '
                  : activeLeftIndex == index - 1
                  ? 'active-after '
                  : ''
              "
            >
              <span>
                <template v-if="!isToggleAside">
                  <i class="iconfont" :class="item.icon"> </i>
                  <span>{{ item.title }}</span>
                </template>
                <template v-else>
                  <el-tooltip :content="item.title" placement="right">
                    <i class="iconfont toggle-icon" :class="item.icon"> </i>
                  </el-tooltip> </template
              ></span>
            </div>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="left-default-nav">
        <el-menu :default-active="activeLeftIndex - leftMenu.length + ''">
          <el-menu-item
            v-for="(item, index) in leftDefaultMenu"
            :disabled="item.title ? false : true"
            :class="
              activeLeftIndex > leftMenu.length - 1 &&
              activeLeftIndex == leftMenu.length + index + 1
                ? 'active-bg '
                : activeLeftIndex > leftMenu.length - 1 &&
                  activeLeftIndex == leftMenu.length + index - 1
                ? 'active-bg '
                : ''
            "
            :index="index + ''"
            :key="index"
            @click="handleChange(item, index, 2)"
          >
            <div
              :class="
                activeLeftIndex > leftMenu.length - 1 &&
                activeLeftIndex == leftMenu.length + index + 1
                  ? 'active-before '
                  : activeLeftIndex > leftMenu.length - 1 &&
                    activeLeftIndex == leftMenu.length + index - 1
                  ? 'active-after '
                  : ''
              "
            >
              <span>
                <template v-if="!isToggleAside">
                  <i class="iconfont" :class="item.icon"> </i>
                  <span>{{ item.title }}</span>
                </template>
                <template v-else>
                  <el-tooltip :content="item.title" placement="right">
                    <i class="iconfont toggle-icon" :class="item.icon"> </i>
                  </el-tooltip>
                </template>
              </span>
            </div>
          </el-menu-item>
        </el-menu>
      </div>
    </el-aside>
    <wConfirmDialog
      ref="outDialog"
      @wConfirm="handleOut"
      title="是否确认退出系统？"
    ></wConfirmDialog>
    <Wdialog
      ref="resetPasswordDialog"
      title="修改密码"
      width="30%"
      @wConfirm="handleResetPassword"
    >
      <el-form
        ref="resetPasswordFormRef"
        :model="resetPasswordFormData"
        :rules="resetPasswordFormRules"
        label-position="top"
        class="organization-form"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="old_pwd" label="旧密码">
              <el-input
                v-model="resetPasswordFormData.old_pwd"
                autocomplete="off"
                placeholder="请输入旧密码"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="new_pwd" label="新密码">
              <el-input
                v-model="resetPasswordFormData.new_pwd"
                autocomplete="off"
                placeholder="请输入新密码"
                clearable
                show-password
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="new_pwd2" label="确认密码">
              <el-input
                v-model="resetPasswordFormData.new_pwd2"
                autocomplete="off"
                placeholder="请再次输入新密码"
                clearable
                show-password
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import cookie from "@/utils/cookie";
import { rightMenuList } from "./menu.js";
import { getSameTokenData } from "@/utils/common.js";
import wConfirmDialog from "@/components/wConfirmDialog/wConfirmDialog.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import API from "@/plugins/api.js";

export default {
  components: { wConfirmDialog, Wdialog },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isCollapse = ref(true);
    const userInfo = computed(() => store.state.saasConfig.userInfo);
    const leftActiveToken = computed(
      () => store.state.saasConfig.leftActiveToken
    );
    const leftMenu = computed(() => store.state.saasConfig.leftMenu);
    const leftDefaultMenu = computed(
      () => store.state.saasConfig.leftDefaultMenu
    );
    const handleChange = (item) => {
      store.commit("setLeftActiveToken", item.token);
    };
    const activeLeftIndex = ref(null);
    const rightActiveToken = computed(
      () => store.state.saasConfig.rightActiveToken
    );
    watch(
      () => leftActiveToken.value,
      (token) => {
        if (token) {
          const allLeftMenu = [...leftMenu.value, ...leftDefaultMenu.value];
          activeLeftIndex.value = allLeftMenu.findIndex(
            (item) => item.token == token
          );
          API.getRightMenu({
            Menu_token: token,
          }).then((res) => {
            const lastRightMenu = getSameTokenData(
              rightMenuList,
              res,
              "api_menu_token"
            );
            if (lastRightMenu.length) {
              store.commit("setRightMenu", lastRightMenu);
              
              if (!rightActiveToken.value) {
                store.commit("setRightActiveToken", lastRightMenu[0].token);
              }
            } else {
              router.push("/auth");
              store.commit("setRightMenu", []);
              store.commit("setRightActiveToken", "");
            }
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    const outDialog = ref(null);
    const handleCommand = (command) => {
      switch (command) {
        case "1":
          resetPasswordDialog.value.show();
          return true;
        case "2":
          outDialog.value.show();
          return true;
        default:
          return false;
      }
    };
    function handleOut() {
      cookie.setCookie("token", "");
      sessionStorage.clear();
      // window.location.href = "/#/login";
      router.push({ path: "/login" });
    }

    const isToggleAside = computed(() => store.state.saasConfig.isToggleAside);

    const resetPasswordDialog = ref(null);
    const resetPasswordFormRef = ref(null);
    const resetPasswordFormData = ref({
      old_pwd: "",
      new_pwd: "",
      new_pwd2: "",
    });

    const resetPasswordFormRules = {
      old_pwd: [
        {
          required: true,
          message: "请输入旧密码",
          trigger: "blur",
        },
      ],
      new_pwd: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
      ],
      new_pwd2: [
        {
          required: true,
          message: "请再次输入新密码",
          trigger: "blur",
        },
      ],
    };

    function openResetPasswordDialog(data) {
      resetPasswordFormData.value["id"] = data.row.id;
    }

    function handleResetPassword() {
      if (resetPasswordFormRef.value) {
        resetPasswordFormRef.value.validate((valid) => {
          if (valid) {
            if (
              resetPasswordFormData.value.new_pwd !=
              resetPasswordFormData.value.new_pwd2
            ) {
              ElMessage.error({
                message: "两次密码不一致！",
              });
              return false;
            }
            API.changePassword({
              old_pwd: resetPasswordFormData.value.old_pwd,
              new_pwd: resetPasswordFormData.value.new_pwd,
            }).then((res) => {
              if (res) {
                ElMessage.success({
                  message: "修改成功，请重新登录！",
                });
                resetPasswordDialog.value.close();
                handleOut();
              }
            });
          }
        });
      }
    }

    return {
      handleChange,
      isCollapse,
      activeLeftIndex,
      leftActiveToken,
      userInfo,
      handleCommand,
      leftDefaultMenu,
      watch,
      outDialog,
      handleOut,
      isToggleAside,
      resetPasswordDialog,
      handleResetPassword,
      resetPasswordFormRef,
      resetPasswordFormData,
      resetPasswordFormRules,
      openResetPasswordDialog,
      leftMenu,
    };
  },
};
</script>

<style lang="scss">
.left-menu-container {
  .el-aside {
    background-color: #fff;
    color: var(--el-text-color-primary);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 50px;
    overflow-x: hidden;
    height: 100%;
    .el-menu {
      padding-left: 18px;
    }

    .logo-container {
      padding: 15px 0;
      background-color: #ffffff;

      .logo {
        width: 141px;
      }
      .small-logo {
        width: 100px;
      }
    }
    .toggle-icon {
      font-size: 24px !important;
    }
    .is-active {
      background-color: var(--theme-bg-color);
      border-radius: 28px 0 0 28px;
    }

    .active-bg {
      background-color: var(--theme-bg-color) !important;
      position: relative;
    }

    .active-after {
      border-radius: 0 28px 0 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      text-align: center;
      color: var(--text-third-color);
    }

    .active-before {
      border-radius: 0 0 28px 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      color: var(--text-third-color);
      text-align: left;
    }

    .el-menu-item {
      color: var(--text-third-color);
      font-size: 14px;

      > div {
        width: 100%;
        text-align: center;
      }
      i {
        font-size: 20px;
        padding-right: 10px;
      }
    }

    .left-nav {
      height: 70%;
      border: none;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .select-container {
      margin: 36px 0 32px 0;
      padding: 0 16px;
      width: 100%;

      .el-dropdown {
        width: 100%;

        .el-button {
          width: 100%;
          background-color: var(--search-bg-color);
          border: none;
          color: var(--theme-color);
          font-size: 16px;
          padding: 16px 0;
          border-radius: 4px;

          > span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding: 0 16px;

            .triangle {
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 8px solid var(--theme-color);
            }
          }
        }
      }
    }

    .el-menu-item.is-active {
      color: var(--theme-color);
      background-color: var(--theme-bg-color) !important;
      font-weight: bold;
    }
    .el-menu-item {
      &:hover {
        background-color: transparent;
        i,
        span {
          color: var(--theme-color);
        }
      }
    }
    .left-default-nav {
      position: relative;
      padding-top: 18px;

      .el-menu {
        border: none;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 160px;
        height: 1px;
        background-color: #e1e4eb;
        top: 0;
        left: 50%;
        margin-left: -80px;
        z-index: 88;
      }
    }
  }
}
</style>
