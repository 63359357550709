<template>
  <div>
    <div class="header-container">
      <i
        class="iconfont toggle-icon"
        :class="isToggleAside ? 'icon-a-jianqu45' : 'icon-a-jianqu46'"
        @click="handleToggleAside"
      ></i>
      <template v-if="topMenu.length">
        <el-menu
          :default-active="topActiveToken"
          mode="horizontal"
          @select="handleSelect"
          :class="topMenu.length <= 1 ? 'hidden' : ''"
        >
          <el-menu-item
            v-for="(item, index) in topMenu"
            :index="item.token"
            :key="index"
            >{{ item.title }}
          </el-menu-item>
        </el-menu>
      </template>

      <div class="header-right">
        <el-dropdown class="flex-center">
          <span class="el-dropdown-link">
            <i class="iconfont icon-a-zu4784"> </i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in skinData"
                @click="checkSkin(item)"
                :class="item.id === activeTheme ? 'active-skin' : ''"
                :key="index"
              >
                {{ item.name }}
                <span v-if="item.id === activeTheme">(当前)</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span class="text-hidden">{{ userInfo.realname }}</span>
        <div class="text-hidden">{{ userInfo.position_name }}</div>
        <el-tooltip content="安全退出" placement="left-start">
          <i class="iconfont icon-a-zu4766" @click="openOutDialog"></i>
        </el-tooltip>
      </div>
    </div>
    <div v-if="rightMenu.length > 1" class="child-nav">
      <el-button
        class="child-btn"
        plain
        round
        v-for="(item, i) in rightMenu"
        :key="i"
        :class="rightActiveToken == item.token ? 'active-btn' : ''"
        @click="handleLink(item)"
      >
        {{ item.title }}
      </el-button>
    </div>
    <wConfirmDialog
      ref="outDialog"
      @wConfirm="handleOut"
      title="是否确认退出系统？"
    ></wConfirmDialog>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getSameTokenData } from "@/utils/common.js";
import {
  topMenuList,
  leftMenuList,
  leftDefalutMenuList,
  rightMenuList,
} from "./menu.js";
import cookie from "@/utils/cookie";
import wConfirmDialog from "@/components/wConfirmDialog/wConfirmDialog.vue";
import API from "@/plugins/api.js";

export default {
  components: {
    wConfirmDialog,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const userInfo = computed(() => store.state.saasConfig.userInfo);

    // 顶部导航
    const topMenu = computed(() => store.state.saasConfig.topMenu);
    const topActiveToken = computed(
      () => store.state.saasConfig.topActiveToken
    );
    onMounted(() => {
      API.getTopMenu().then((res) => {
        const lastTopMenu = getSameTokenData(topMenuList, res);
        if (lastTopMenu.length) {
          store.commit("setTopMenu", lastTopMenu);
          if (!topActiveToken.value) {
            store.commit("setTopActiveToken", lastTopMenu[0].token);
          }
        } else {
          router.push("/auth");
          store.commit("setTopActiveToken", "");
          store.commit("setLeftActiveToken", "");
          store.commit("setLeftMenu", []);
          store.commit("setLeftDefaultMenu", []);
          store.commit("setRightActiveToken", "");
          store.commit("setRightMenu", []);
        }
      });
    });

    const handleSelect = (key) => {
      store.commit("setTopActiveToken", key);
    };
    const leftActiveToken = computed(
      () => store.state.saasConfig.leftActiveToken
    );
    watch(
      () => topActiveToken.value,
      (token) => {
        if (token) {
          API.getLeftMenu({
            Menu_token: token,
          }).then((res) => {
            const lastLeftArrMenu = getSameTokenData(leftMenuList, res);
            const lastLeftDefalutArrMenu = getSameTokenData(
              leftDefalutMenuList,
              res
            );

            if (
              lastLeftArrMenu.length == 0 &&
              lastLeftDefalutArrMenu.length == 0
            ) {
              router.push("/auth");
              store.commit("setLeftActiveToken", "");
              store.commit("setLeftMenu", []);
              store.commit("setLeftDefaultMenu", []);
              store.commit("setRightActiveToken", "");
              store.commit("setRightMenu", []);
            } else {
              store.commit("setLeftMenu", lastLeftArrMenu);
              store.commit("setLeftDefaultMenu", lastLeftDefalutArrMenu);
              const allLeftMenu = [
                ...lastLeftArrMenu,
                ...lastLeftDefalutArrMenu,
              ];
              if (!leftActiveToken.value) {
                store.commit("setLeftActiveToken", allLeftMenu[0].token);
              }
            }
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const rightMenu = computed(() => store.state.saasConfig.rightMenu);
    const rightActiveToken = computed(
      () => store.state.saasConfig.rightActiveToken
    );
    function handleLink(item) {
      if (item.token != rightActiveToken.value) {
        // 点击的不是已经选中的  才触发修改
        store.commit("setRightActiveToken", item.token);
      }
    }
    watch(
      () => rightActiveToken.value,
      (token) => {
        if (token) {
          const currentMenu = rightMenuList.find(
            (item) => item.token === token
          );
          API.getRightMenu({
            Menu_token: token,
          }).then((res) => {
            store.commit("setRightBtn", res);
            currentMenu && currentMenu.path
              ? router.push(currentMenu.path)
              : router.push("/auth");
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    watch(
      () => leftActiveToken.value,
      () => {
        store.commit("setRightActiveToken", "");
      },
      {
        deep: true,
      }
    );

    /**
     * 展开收起
     * */
    const isToggleAside = computed(() => store.state.saasConfig.isToggleAside);
    function handleToggleAside() {
      store.commit("setToggleAside", !isToggleAside.value);
    }
    /**
     * 退出系统
     * */
    const dialogTitle = "退出系统";
    const dialogWidth = "40%";
    const outDialog = ref(null);

    function openOutDialog() {
      outDialog.value.show();
    }
    const handleOut = () => {
      cookie.setCookie("token", "");
      sessionStorage.clear();
      router.push({ path: "/login" });
    };
    /**
     * 换肤
     * */

    const skinData = ref([
      {
        name: "夏雨蓝",
        id: "blue",
      },
      {
        name: "薄雾黑",
        id: "black",
      },
      {
        name: "暖秋金",
        id: "gold",
      },
      {
        name: "春木绿",
        id: "green",
      },
    ]);
    const activeTheme = computed(() => store.state.saasConfig.activeTheme);
    const checkSkin = (item) => {
      store.commit("setActiveTheme", item.id);
    };
    return {
      dialogTitle,
      dialogWidth,
      openOutDialog,
      outDialog,
      activeTheme,
      checkSkin,
      skinData,
      topMenu,
      rightMenu,
      topActiveToken,
      handleSelect,
      userInfo,
      handleOut,
      handleLink,
      handleToggleAside,
      isToggleAside,
      rightActiveToken,
    };
  },
};
</script>

<style lang="scss">
.active-skin {
  color: var(--theme-color) !important;
  background-color: var(--search-bg-color) !important;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .toggle-icon {
    font-size: 30px;
    color: var(--theme-color);
    cursor: pointer;
  }
  .hidden {
    visibility: hidden;
  }
  .el-dropdown__popper {
    --el-dropdown-menu-box-shadow: var(--search-bg-color) !important;
    --el-dropdown-menuItem-hover-fill: var(--search-bg-color) !important;
    --el-dropdown-menuItem-hover-color: var(--search-bg-color) !important;
    --el-dropdown-menu-index: 10;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid var(--theme-color);
    color: var(--theme-color) !important;
    background-color: var(--search-bg-color);
  }

  .header-right {
    width: 24%;
    justify-content: flex-end;
    padding-right: 30px;
    color: var(--text-third-color);
    font-size: 15px;
    display: flex;

    > span {
      text-align: right;
    }

    > div {
      margin: 0 26px 0 14px;
    }

    i {
      cursor: pointer;
      &:hover {
        font-weight: bold;
        color: var(--theme-color);
      }
    }
  }

  .el-menu {
    flex: 1;
  }
}

.el-header {
  height: auto;
  line-height: 80px;
  padding: 0px;

  .el-menu--horizontal > .el-menu-item {
    height: 80px;
  }

  .el-menu--horizontal {
    border: none;
  }
}

.child-nav {
  padding: 15px 20px 0 20px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow: -moz-scrollbars-none;
  background-color: var(--theme-bg-color);

  .el-button {
    min-height: 32px !important;
    height: 32px;
    line-height: 0px;
  }

  .child-btn {
    background-color: var(--theme-bg-color);
    color: var(--theme-color);
    border-color: var(--theme-color);
  }

  .active-btn {
    color: #ffffff;
    background-color: var(--theme-color);
    outline: 0;
  }
}

.child-nav::-webkit-scrollbar {
  display: none;
}
</style>
